import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { useContext } from "react";
import ContextStore from "./context/ContextStore";
export default function RowRadioButtonsGroup(props) {

  const storeContext = useContext(ContextStore);

  

  return (
    <Box sx={{}}>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Filters</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={props.bat ? (e)=>{storeContext.storeBatFilterChange(e, props.match)} : (e)=>{storeContext.storeBowlFilterChange(e, props.match)}}
        >
          <FormControlLabel
            value={props.bat ? "bat_sa_score" : "bowl_sa_score"}
            control={<Radio />}
            label={props.bat ? "Top Batters by SA Score" : "Top Bowlers by SA Score"}
          />
          <FormControlLabel
            value={props.bat ? "bat_runs" : "bowl_wickets"}
            control={<Radio />}
            label={props.bat ? "Top Batters by Runs" : "Top Bowlers by Wickets"}
          />
          <FormControlLabel
            value={props.bat ? "bat_sr" : "bowl_economy"}
            control={<Radio />}
            label={props.bat ? "Top Batters by S/R" : "Top Bowlers by Economy"}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
