import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import {
  fetchBatData,
  fetchByFilters,
  fetchBowlData,
  searchByName,
  fetchWinData,
} from "../apicalls";
const ContextStore = React.createContext({
  batData: [],

  batSearch: [],
  storeBatData: () => {},
  storeBatFilterChange: () => {},
  batFilter: "All",
  loadingBatTable: false,
  setLoadingBatTable: () => {},
  loadingBatSearchTable: false,
  loadingBowlSearchTable: false,
  winData: [],
  storeWinData: () => {},

  bowlData: [],

  bowlSearch: [],
  setBatData: () => {},
  setBowlData: () => {},
  storeBowlData: () => {},
  storeBowlFilterChange: () => {},
  bowlFilter: "All",
  loadingBowlTable: false,
  setLoadingBowlTable: () => {},
  handleSearchInput: () => {},
  searchInput: "",
  setSearchInput: () => {},

  selectedListButton: [false, false],
  setSelectedListButton: () => {},
});

export const ContextStoreProvider = (props) => {
  const [batData, setBatData] = useState([{ id: 1, name: "demo" }]);
  const [batSearch, setBatSearch] = useState([]);
  const [loadingBatSearchTable, setLoadingBatSearchTable] = useState(false);
  const [batFilter, setBatFilter] = useState("All");
  const [loadingBatTable, setLoadingBatTable] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [selectedListButton, setSelectedListButton] = useState([true, false, false]);

  const [bowlData, setBowlData] = useState([{ id: 1, name: "demo" }]);
  const [bowlSearch, setBowlSearch] = useState([]);
  const [bowlFilter, setBowlFilter] = useState("All");
  const [loadingBowlTable, setLoadingBowlTable] = useState(false);
  const [loadingBowlSearchTable, setLoadingBowlSearchTable] = useState(false);

  const [winData, setWinData] = useState([]);

  const storeBatData = async (match) => {
    // console.log("received match as -> ", match);

    // setSearchInput("")
    setLoadingBatTable(true);
    const data = await fetchBatData(match);
    setBatData(data);
    setLoadingBatTable(false);
  };

  const storeBowlData = async (match) => {
    // setSearchInput("")
    setLoadingBowlTable(true);
    const data = await fetchBowlData(match);
    setBowlData(data);
    setLoadingBowlTable(false);
  };

  const storeBatFilterChange = async (event, match) => {
    setSearchInput("");
    setLoadingBatTable(true);
    // console.log("changing bat filter for... ", event.target.value);
    setBatFilter(event.target.value);
    const data = await fetchByFilters(event.target.value, match);
    setBatData(data);
    setLoadingBatTable(false);
  };

  const storeWinData = async (match) => {
    const data = await fetchWinData(match);

    const sData = [
      { title: "Bat First", value: data.batting_first_p, color: "#89CFF0" },
      { title: "Bat Second", value: data.batting_second_p, color: "#F0FFFF" },
      { title: "Tie", value: data.ties, color: "#0047AB" },
    ];

    setWinData(sData);
    // setBatData(data);
    // setLoadingBatTable(false);
  };

  const storeBowlFilterChange = async (event, match) => {
    setSearchInput("");
    setLoadingBowlTable(true);
    // console.log("changing bowl filter for... ", event.target.value, match);
    setBowlFilter(event.target.value);
    const data = await fetchByFilters(event.target.value, match);
    setBowlData(data);
    setLoadingBowlTable(false);
  };

  const handleSearchInput = async (match) => {
    // console.log("search input .. ", searchInput);
    if (searchInput != "") {
      setLoadingBowlTable(true);
      setLoadingBatTable(true);
      setLoadingBatSearchTable(true);
      setLoadingBowlSearchTable(true);
      const data = await searchByName(searchInput, match);

      setBatData(data["name"]["result_list_bat"]);
      setBowlData(data["name"]["result_list_bowl"]);

      setBatSearch(data["match"]["bat"]);
      setBowlSearch(data["match"]["bowl"]);
      setLoadingBowlTable(false);
      setLoadingBatTable(false);
      setLoadingBatSearchTable(false);
      setLoadingBowlSearchTable(false);
    }
  };

  const contextData = {
    batData: batData,
    storeBatData: storeBatData,
    storeBatFilterChange: storeBatFilterChange,
    batFilter: batFilter,
    loadingBatTable: loadingBatTable,
    setLoadingBatTable: setLoadingBatTable,
    searchInput: searchInput,
    handleSearchInput: handleSearchInput,
    bowlSearch: bowlSearch,
    batSearch: batSearch,
    loadingBatSearchTable: loadingBatSearchTable,
    loadingBowlSearchTable: loadingBowlSearchTable,
    setBatData: setBatData,
    setBowlData: setBowlData,

    winData: winData,
    storeWinData: storeWinData,

    selectedListButton: selectedListButton,
    setSelectedListButton: setSelectedListButton,

    bowlData: bowlData,
    storeBowlData: storeBowlData,
    storeBowlFilterChange: storeBowlFilterChange,
    bowlFilter: bowlFilter,
    loadingBowlTable: loadingBowlTable,
    setLoadingBatTable: setLoadingBatTable,
    setSearchInput: setSearchInput,
  };
  return (
    <ContextStore.Provider value={contextData}>
      {props.children}
    </ContextStore.Provider>
  );
};

export default ContextStore;
