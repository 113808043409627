import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DataTable from "./DataTable";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ContextStore from "./context/ContextStore";
import { useContext } from "react";
import Button from "@mui/material/Button";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PitchwiseStatistics from "./PitchwiseStatistics";
import { PieChart } from "react-minimal-pie-chart";
import { Link } from "react-router-dom";
import PointsTable from "./PointsTable";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

import { tournaments } from "./tournaments";

const drawerWidth = 240;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: "10%",
  width: "100%",
  maxHeight: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));



function ResponsiveDrawer(props) {
  const storeContext = useContext(ContextStore);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [match, setMatch] = React.useState(tournaments[tournaments.length - 1].id);

  const selectedTournament = tournaments.find(tournament => tournament.id === match);



  React.useEffect(() => {
    // console.log("Props received -> ", props.match);
    storeContext.storeWinData(match);
  }, [match]);

  const handleSearchChange = (event) => {
    // console.log("called with value", event.target.value);

    storeContext.setSearchInput(event.target.value);
  };

  const handleListClick = (buttonClicked) => {
    if (buttonClicked == "points_table") {
      storeContext.setSelectedListButton([false, false, true]);
    } else if (buttonClicked == "pitch_stats") {
      storeContext.setSelectedListButton([false, true, false]);
    } else {
      storeContext.setSelectedListButton([true, false, false]);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            value="Check Statistics"
            selected={storeContext.selectedListButton[0]}
            onClick={(event) => {
              handleListClick("stats");
            }}
          >
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText primary="Check Statistics" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            value="Check Pitchwise Statistics"
            selected={storeContext.selectedListButton[1]}
            onClick={(event) => {
              handleListClick("pitch_stats");
            }}
          >
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Check Pitchwise Statistics" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            value="Points Table"
            selected={storeContext.selectedListButton[1]}
            onClick={(event) => {
              handleListClick("points_table");
            }}
          >
            <ListItemIcon>
              <SportsScoreIcon />
            </ListItemIcon>
            <ListItemText primary="Points Table" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const defaultLabelStyle = {
    fontSize: "5px",
    fontFamily: "sans-serif",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              //   marginRight: 50,
              //   border: "1px solid black"
            }}
          >
            <Typography variant="h6" noWrap component="div">
              Battrick U600 Stats
            </Typography>

            <select value={match} onChange={(e) => setMatch(e.target.value)}>
              {tournaments.map(
                (tournament) => (
                  <option value={tournament.id} key={tournament.id}>
                    {tournament.name}
                  </option>
                )
              )}
            </select>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: { xs: 'center', sm: 'left' },
        gap: { xs: 1, sm: 0 } // Adds a small space between items on smaller screens
      }}>
        <h1>
          Data for {selectedTournament ? selectedTournament.name : 'Unknown Tournament'}
        </h1>

        <Typography variant="body1" sx={{ mb: { xs: 2, sm: 0 } }}>
          Data last updated on: {process.env.REACT_APP_LAST_UPDATED}
        </Typography>
          
        </Box>

        

        {/* <h1>Data for {selectedTournament ? selectedTournament.name : 'Unknown Tournament'}</h1>
        <p>Last updated on: 26/01/24</p> */}


        {storeContext.selectedListButton[0] ? (
          <div className="stats">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    variant="outlined"
                    placeholder="Player search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearchChange}
                    sx={{ border: "1px solid black", width: "200px" }}
                    value={storeContext.searchInput}
                  />
                </Search>
                <Button
                  variant="contained"
                  onClick={() => {
                    storeContext.handleSearchInput(match);
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
            <h1>Batters</h1>

            <DataTable bat={true} isSearch={false} match={match} />


            {/* <DataTable bat={true} isSearch={false} match={"ipl"}/> */}

            <h1>Bowlers</h1>

            <DataTable bat={false} isSearch={false} match={match} />


            {/* <DataTable bat={false} isSearch={false} match={"ipl"}/> */}

            {storeContext.searchInput.length > 0 ? (
              <>
                <h1>Previous matches for {storeContext.searchInput}</h1>
                <h2>Batting</h2>
                <DataTable bat={true} isSearch={true} />
                <h2>Bowling</h2>
                <DataTable bat={false} isSearch={true} />
              </>
            ) : null}

            {/* {props.match === "ipl" ? (<><h2>Points Table</h2><PointsTable /></>):(null)} */}

            {storeContext.winData ? (
              <>
                <h3>Batting first vs Batting second wins</h3>

                <PieChart
                  style={{ height: "300px" }}
                  data={storeContext.winData}
                  segmentsShift={(index) => (index === 0 ? 0.5 : 0.5)}
                  label={({ dataEntry }) => dataEntry.value}
                  labelStyle={{
                    ...defaultLabelStyle,
                  }}
                  radius={40}
                />
              </>
            ) : null}
          </div>
        ) : storeContext.selectedListButton[1] ? (
          <PitchwiseStatistics match={match} />
        ) : storeContext.selectedListButton[2] ? (
          <>
            <h2>Points Table</h2>
            <PointsTable match={match} />
          </>
        ) : null}
      </Box>
    </Box>
  );
}

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default ResponsiveDrawer;
