import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import Dashboard from "./Dashboard";
import { ContextStoreProvider } from "./context/ContextStore";
import { BrowserRouter, Route, Routes } from "react-router-dom";


ReactDOM.createRoot(document.querySelector("#root")).render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
    <ContextStoreProvider>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard match={"od"} />} />
          <Route path="/ipl" element={<Dashboard match={"ipl"} />} />
        </Routes>
      </BrowserRouter>
      {/* <Dashboard /> */}
      </ContextStoreProvider>
    </StyledEngineProvider>
  // </React.StrictMode>
);