import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import RowRadioButtonsGroup from "./RadioButtonGroup";
import Box from "@mui/material/Box";
import { useContext } from "react";
import ContextStore from "./context/ContextStore";
import { styled } from "@mui/material/styles";
import { SportsGolf } from "@mui/icons-material";

// const StyledDataGrid = withStyles({
//   root: {
//     "& .MuiDataGrid-renderingZone": {
//       maxHeight: "none !important"
//     },
//     "& .MuiDataGrid-cell": {
//       lineHeight: "unset !important",
//       maxHeight: "none !important",
//       whiteSpace: "normal"
//     },
//     "& .MuiDataGrid-row": {
//       maxHeight: "none !important"
//     }
//   }
// })(DataGrid);
const batColumns = [
  // { field: 'id', headerName: 'id', width: 70 },
  { field: "player_name", headerName: "Last Name", minWidth: 100 },
  { field: "team", headerName: "Team", minWidth: 100 },
  {
    field: "average",
    headerName: "Average",
    type: "number",
    minWidth: 80,
  },
  { field: "runs", headerName: "Runs", type: "number", minWidth: 70 },
  {
    field: "no_matches",
    headerName: "Matches",
    type: "number",
    minWidth: 70,
  },
  {
    field: "strike_rate",
    headerName: "S/R",
    type: "number",
    minWidth: 80,
  },
  {
    field: "sa_score",
    headerName: "SA Score",
    type: "number",
    minWidth: 80,
  },
  {
    field: "not_out",
    headerName: "Not Outs",
    type: "number",
    minWidth: 80,
  },
  {
    field: "sixes",
    headerName: "Sixes",
    type: "number",
    minWidth: 80,
  },
  {
    field: "fours",
    headerName: "Fours",
    type: "number",
    minWidth: 80,
  }
];
const batColumnsSearch = [
  // { field: 'id', headerName: 'id', width: 70 },
  { field: "name", headerName: "Last Name", minWidth: 100 },
  { field: "match_date", headerName: "Date", minWidth: 100 },
  { field: "match_name", headerName: "Match", minWidth: 150 },
  { field: "team", headerName: "Team", minWidth: 100 },
  {
    field: "pitch_type",
    headerName: "Pitch",
    type: "number",
    minWidth: 80,
  },
  { field: "runs", headerName: "Runs", minWidth: 70 },

  {
    field: "strike_rate",
    headerName: "S/R",
    type: "number",
    minWidth: 80,
  },
];
const bowlColumns = [
  // { field: 'id', headerName: 'id', width: 70 },
  { field: "player_name", headerName: "Last Name", minWidth: 100 },
  { field: "team", headerName: "Team", minWidth: 100 },
  {
    field: "bowling_average",
    headerName: "B.Average",
    type: "number",
    width: 80,
  },
  {
    field: "economy",
    headerName: "Economy",
    type: "number",
    width: 80,
  },
  {
    field: "wickets",
    headerName: "Wickets",
    type: "number",
    width: 80,
  },

  {
    field: "no_matches",
    headerName: "Matches",
    type: "number",
    width: 80,
  },
  {
    field: "bowling_strike_rate",
    headerName: "S/R",
    type: "number",
    width: 90,
  },
  {
    field: "bowling_sa_score",
    headerName: "SA Score",
    type: "number",
    width: 90,
  },
];

const bowlColumnsSearch = [
  // { field: 'id', headerName: 'id', width: 70 },
  { field: "name", headerName: "Last Name", minWidth: 100 },
  { field: "team", headerName: "Team", minWidth: 100 },
  { field: "match_date", headerName: "Date", minWidth: 100 },
  { field: "match_name", headerName: "Match", minWidth: 150 },
  {
    field: "pitch_type",
    headerName: "Pitch",
    type: "number",
    minWidth: 80,
  },
  {
    field: "economy",
    headerName: "Economy",
    type: "number",
    width: 80,
  },
  {
    field: "wickets",
    headerName: "Wickets",
    type: "number",
    width: 80,
  },
  {
    field: "runs",
    headerName: "Runs given",
    type: "number",
    width: 90,
  },
  {
    field: "overs",
    headerName: "Overs",
    type: "number",
    width: 90,
  },
];

const rows = [{ id: 1, lastName: "Snow", firstName: "Jon", age: 35 }];

export default function DataTable(props) {
  const storeContext = useContext(ContextStore);

  const filterOptions = [{ name: "All" }];

  if (props.bat) {
    if (props.isSearch) {
      const tableHeight =
        storeContext.batSearch.length < 10
          ? 40 * storeContext.batSearch.length + 140
          : 400;
    } else {
      const tableHeight =
        storeContext.batData.length < 10
          ? 40 * storeContext.batData.length + 140
          : 400;
    }
  } else {
    if (props.isSearch) {
      const tableHeight =
        storeContext.bowlSearch.length < 10
          ? 40 * storeContext.bowlSearch.length + 140
          : 400;
    } else {
      const tableHeight =
        storeContext.bowlSearch.length < 10
          ? 40 * storeContext.bowlSearch.length + 140
          : 400;
    }
  }

  useEffect(() => {
    if (!props.pitchWise) {
      if (storeContext.searchInput.length == 0) {

        if (props.bat) {
          storeContext.storeBatData(props.match);
        } else {
          storeContext.storeBowlData(props.match);
        }
      }
    }
  }, [props.match]);

  const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    height: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  }));

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {props.isSearch || props.pitchWise ? null : <RowRadioButtonsGroup bat={props.bat} match={props.match}/>}
      <StyledBox
        sx={{
          height:
            props.bat && !props.isSearch
              ? storeContext.batData.length > 10
                ? 400
                : storeContext.batData.length * 40 + 150
              : !props.isSearch
              ? storeContext.bowlData.length > 10
                ? 400
                : storeContext.bowlData.length * 40 + 150
              : 400,
        }}
      >
        <DataGrid
          title="All Batters"
          getRowHeight={() => "auto"}
          loading={
            props.bat
              ? props.isSearch
                ? storeContext.loadingBatSearchTable
                : storeContext.loadingBatTable
              : props.isSearch
              ? storeContext.loadingBowlSearchTable
              : storeContext.loadingBowlTable
          }
          rows={
            props.bat
              ? props.isSearch
                ? storeContext.batSearch
                : storeContext.batData
              : props.isSearch
              ? storeContext.bowlSearch
              : storeContext.bowlData
          }
          columns={
            props.bat
              ? props.isSearch
                ? batColumnsSearch
                : batColumns
              : props.isSearch
              ? bowlColumnsSearch
              : bowlColumns
          }
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </StyledBox>
    </Box>
  );
}
