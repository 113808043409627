import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { fetchPitchwiseData } from "./apicalls";
import { useContext } from "react";
import ContextStore from "./context/ContextStore";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "./DataTable";

function PitchwiseStatistics(props) {
  const [pitch, setPitch] = React.useState("");
  const [allPitches, setAllPitches] = React.useState([]);
  const [allPitchStats, setAllPitchStats] = React.useState({});

  const storeContext = useContext(ContextStore);

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await fetchPitchwiseData(props.match);

      setAllPitchStats(response);
      // const responseJson  = response.json()
      setAllPitches(response["pitch"]);

      
    }
    fetchData();
  }, [props.match]);

  const handleChange = (event) => {
    const newPitchType = event.target.value;

    setPitch(newPitchType);

    for (const object in allPitchStats["stats"]) {
      if (allPitchStats["stats"][object]["pitch_type"] === newPitchType) {
        storeContext.setBatData(
          allPitchStats["stats"][object]["top_batters_by_sa_score"]
        );
        storeContext.setBowlData(
          allPitchStats["stats"][object]["top_bowlers_by_sa_score"]
        );
      }
    }
  };

  const greeting = "This page is under development. Please return soon.";

  return (
    <div>
      {allPitches.length > 0 ? (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Pitch</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={pitch}
            label="Pitch"
            onChange={handleChange}
          >
            {allPitches.map((pitch) => (
              <MenuItem key={pitch} value={pitch}>
                {pitch}
              </MenuItem>
            ))}
            {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
          <FormHelperText>Select a Pitch type</FormHelperText>
        </FormControl>
      ) : (
        <CircularProgress />
      )}

      {pitch.length > 0 ? (
        <div>
          <h1>Top Batters in {pitch} pitch</h1>
          <DataTable bat={true} isSearch={false} pitchWise={true} />

          <h1>Top Bowlers in {pitch} pitch</h1>
          <DataTable bat={false} isSearch={false} pitchWise={true} />
        </div>
      ) : null}
    </div>
  );
}

export default PitchwiseStatistics;
