
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress, Typography } from '@mui/material';


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: 800,
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));
  
  const StyledTable = styled(Table)(({ theme }) => ({
    '& th': {
      fontWeight: 'bold',
      backgroundColor: theme.palette.grey[100],
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
    '& th, & td': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        padding: theme.spacing(1),
      },
    },
  }));
  
  const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  }));
  

  
  
  
  

function PointsTable(props) {
  const [pointsData, setPointsData] = useState([]);

  useEffect(() => {
    
    fetch(process.env.REACT_APP_BACKEND_SERVER + "get-points-table/"+props.match)
      .then((response) => response.json())
      .then((data) => setPointsData(data))
      .catch((error) => console.error(error));
  }, [props.match]);

  return (

    

    
    <StyledTableContainer component={Paper}>
        

        

       
      <StyledTable aria-label="Points Table">
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell align="right">Played</TableCell>
            <TableCell align="right">Won</TableCell>
            <TableCell align="right">Lost</TableCell>
            <TableCell align="right">Tied</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {pointsData.map((teamData) => (
            <TableRow key={teamData.team}>
              <TableCell component="th" scope="row">
                {teamData.team}
              </TableCell>
              <TableCell align="right">{teamData.matches_played}</TableCell>
              <TableCell align="right">{teamData.wins}</TableCell>
              <TableCell align="right">{teamData.losses}</TableCell>
              <TableCell align="right">{teamData.ties}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      {pointsData.length > 0 ? (null) : (<CircularProgress />)}
    </StyledTableContainer>
  );
}

export default PointsTable;
