export const fetchBatData = async (match) => {
  // const matchType = match === "ipl" ? 3 : 4;
  // console.log("setting bat data called.......");
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER + "get-unique-batters/" + match
  );
  const data = await response.json();
  return data;
};

export const fetchByFilters = async (batFilter, match) => {
  const matchType = match === "ipl" ? 3 : 4;
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER +
      "get-by-filters/" +
      batFilter +
      "/" +
      match
  );
  const data = await response.json();
  return data;
};

export const searchByName = async (value, match) => {
  const matchType = match === "ipl" ? 3 : 4;
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER +
      "search-by-player/" +
      value +
      "/" +
      match
  );
  const data = await response.json();

  const response_matches = await fetch(
    process.env.REACT_APP_BACKEND_SERVER +
      "get_all_matches/" +
      match +
      "?player_name=" +
      value
  );
  const data_player = await response_matches.json();

  return { name: data, match: data_player };
};

export const fetchBowlData = async (match) => {
  const matchType = match === "ipl" ? 3 : 4;
  // console.log("setting bowl data called.......");
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER + "get-unique-bowlers/" + match
  );
  const data = await response.json();
  return data;
};

export const fetchPitchwiseData = async (match) => {
  // console.log("setting bat data called.......");
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER +
      "get-pitchwise-statistics/" +
      match
  );
  const data = await response.json();
  return data;
};


export const fetchWinData = async (match) => {
  
  const response = await fetch(
    process.env.REACT_APP_BACKEND_SERVER +
      "get-winning-team-data/" +
      match
  );
  const data = await response.json();
  return data;
};
